<template>
	<v-layout v-if="companiesList" column fill-height>
		<v-flex fill-height>
			<CustomersList />
		</v-flex>
	</v-layout>
</template>

<script>
import { mapState } from 'vuex'
import CustomersManagerModuleGuard from '@/mixins/ModulesGuards/CustomersManager/CustomersManagerModuleGuard'

export default {
	name: 'CustomersManager',
	components: {
		CustomersList: () => ({
			component: import('@/components/CustomersManager/CustomersList')
		})
	},
	mixins: [CustomersManagerModuleGuard],
	data: function () {
		return {
			loading: true,
			viewList: true,
			viewListDetails: false,
			viewCompanyDetails: false,
			search: ''
		}
	},
	computed: {
		...mapState({
			companiesList: state => state.company.list
		}),
		companies: function () {
			return this.companiesList.filter(company => company.accounting_firm_id === this.accountingFirmId)
		}
	},
	watch: {
		viewListDetails: function (val) {
			this.viewList = !val
		}
	}
}
</script>
